import Keycloak from 'keycloak-js'
import keycloakConfigInfo from './keycloakConfig.json'

const keycloakConfig = keycloakConfigInfo[Object.keys(keycloakConfigInfo).find(v => new RegExp(v).test(location.hostname))!]
const keycloak = new Keycloak(keycloakConfig)
export const initKeycloak = () => {
  return new Promise((resolve, reject) => {
    keycloak
      .init({
        onLoad: 'check-sso'
      }) // 使用 check-sso 模式，或 'login-required' 来强制登录
      .then((authenticated) => {
        if (authenticated) {
          resolve(authenticated)

        } else {
          reject('用户未认证')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const login = () => {
  try {
    keycloak.login({redirectUri: `${location.origin}/ui`})
  } catch (error) {
    console.log(error)
  }
}

export const logout = () => {
  return keycloak.logout()
}

export const getToken = () => {
  return keycloak.token
}

export const getTokenParsed = () => {
  return keycloak.tokenParsed
}

export const isLoggedIn = () => {
  return keycloak.authenticated
}

export const refreshToken = async () => {
  try {
    await keycloak.updateToken(50)
  } catch (error) {
    // console.error('Error refreshing token:', error)
  }
}

// initKeycloak();

export default keycloak
