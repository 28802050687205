import { QueryTypeEnum } from '../../assets/types/apiTypes';

export const columnsInfo = {
  icloud: [{
      title: '姓名',
      dataIndex: 'name',
  }]
}

export const hiddenItemListKey = 'hidden_item_list'

export type FetchOrgType = {
  name: string;
  id: number;
  shot_name: string;
}

export type FetchFilterInfoType = {
  page_num: number;
  page_size: number;
  query_type?: QueryTypeEnum;
  from?: number;
  to?: number;
  search_key?: string;
  org_id_list?: number[];
}

export const initFelterInfo: FetchFilterInfoType = {
  page_num: 1,
  page_size: 50,
}
