export enum DateRangeEnum {
  other = 'other',
  thisMonth = 'thisMonth',
  oneWeek = 'oneWeek',
  oneMonth = 'oneMonth',
  threeMonth = 'threeMonth',
}

export const defaultOptions = [{
  label: '其他',
  value: DateRangeEnum.other,
  key: DateRangeEnum.other,
},{
  label: '本月',
  value: DateRangeEnum.thisMonth,
  key: DateRangeEnum.thisMonth,
},{
  label: '一周',
  value: DateRangeEnum.oneWeek,
  key: DateRangeEnum.oneWeek,
},{
  label: '一个月',
  value: DateRangeEnum.oneMonth,
  key: DateRangeEnum.oneMonth,
},{
  label: '三个月',
  value: DateRangeEnum.threeMonth,
  key: DateRangeEnum.threeMonth,
}]