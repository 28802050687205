import { Button, Col, DatePickerProps, Form, FormInstance, Input, Modal, Row, Select, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './index.module.less'
import { initFelterInfo } from './config'
import { DefaultOptionType } from 'antd/es/select'
import { FilterColumnsForm } from './component/FIlterColumnsForm'
import { getHiddenItem, setHiddenItem } from './utils'
import { SimplePagination } from '../SimplePagination'
import moment from 'moment'
import { getOrgList, searchAccuracyList } from '../../utils/request/dcApi'
import { FetchAccuracyApi, QueryTypeEnum } from '../../assets/types/apiTypes'
import { ColumnsType } from '../../assets/types/baseApi'
import { DateRange } from '../DateRange'
import { DateRangeEnum } from '../DateRange/config'

let allColumns: ColumnsType[] = []; // 全体columns

export default () => {
  let location = useLocation(); // 用于搜索
  const [data, setDate] = useState<any[]>([])
  const [orgList, setOrgList] = useState<DefaultOptionType[]>([])
  const [column, setColumn] = useState<ColumnsType[]>([])
  const [isShowFilterButton, setIsShowFilterButton] = useState(false)
  const [filterColumnsList, setFilterColumnsList] = useState<ColumnsType[]>([])
  const [visibleFilterModal, setVisibleFilterModal] = useState(false)
  const [filterInfo, setFilterInfo] = useState<FetchAccuracyApi>(initFelterInfo)

  const filterColumnsRef = useRef<FormInstance>(null)
  const filterRef = useRef<FormInstance>(null)

  const initOrgList = async () => {
    const res = await getOrgList()
    setOrgList(res.map(v => ({ label: v.name, value: v.id, key: v.id })))
  }

  const initSearch = async () => {
    try {
      const queryType = location.pathname.split('/')[2]
      if (!queryType) return
      
      const res = await searchTable(initFelterInfo)
      allColumns = res.title;
      const hiddneInfo = getHiddenItem()[queryType!] || {}
      const filterList = res.title.filter(v => v.isFilter)
      setColumn(res.title.filter(v => !hiddneInfo[v.dataIndex]))
      setFilterColumnsList(filterList)
      setIsShowFilterButton(!!filterList.length)
    } catch (error) {

    }
  }

  const searchTable = async (query: Partial<FetchAccuracyApi> = {}) => {
    const queryType = location.pathname.split('/')[2] as QueryTypeEnum
    const res = await searchAccuracyList({query_type: queryType, ...query} as FetchAccuracyApi)
    setDate(res.data)

    return res
  }

  const handleChangeFilter = async (query: Partial<FetchAccuracyApi>, clearOther: boolean = false) => {
    try {
      const resQuery = { ...(clearOther? {}: filterInfo), ...query } as FetchAccuracyApi
      setFilterInfo(resQuery)
      await searchTable(resQuery)
    } catch (error) {
      
    }
  }

  const handleGetFilter = (val: any = {}) => {
    if (val.rangeType === DateRangeEnum.oneWeek) {
      return [moment().subtract(1, 'week').startOf('day').valueOf(), moment().endOf('day').valueOf()]
    } else if (val.rangeType === DateRangeEnum.thisMonth) {
       return [moment().startOf('months').valueOf(), moment().endOf('day').valueOf()]
    }else if (val.rangeType === DateRangeEnum.oneMonth) {
       return [moment().subtract(1, 'months').startOf('day').valueOf(), moment().endOf('day').valueOf()]
    } else if (val.rangeType === DateRangeEnum.threeMonth) {
      return [moment().subtract(3, 'months').startOf('day').valueOf(), moment().endOf('day').valueOf()]
    } else if (val.rangeType === DateRangeEnum.other && val.date) {
      return [val.date[0].startOf('day').valueOf(), val.date[1].endOf('day').valueOf()]
    }

    return []
  }

  const handleSearch = () => {
    const val = filterRef.current?.getFieldsValue()
    const [from, to] = handleGetFilter(val.period)
    const org_id_list = val.org_id_list?.length? val.org_id_list: undefined
    handleChangeFilter({...initFelterInfo, ...val, period: undefined, from, to, org_id_list}, true)
  }

  const handleClearSearch = () => {
    filterRef.current?.resetFields()
    handleSearch()
  }

  const handleOk = () => {
    const val = filterColumnsRef.current?.getFieldsValue()
    const queryType = location.pathname.split('/')[2]
    setHiddenItem(queryType!, val)
    setColumn(allColumns.filter(v => !val[v.dataIndex]))
    
    handleCancel()
  }

  const handleCancel = () => {
    setVisibleFilterModal(false)
  }

  const handleExport = async (page_num = 1, res: string[] = []) => {
    if (!res.length) {
      res.push(column.map(v => v.title).join(',') + '\n')
    }
    const query_type = location.pathname.split('/')[2] as QueryTypeEnum
    const data = await searchAccuracyList({ ...filterInfo, page_num, query_type })
    res.push(data.data.map(v => column.map(val => v[val.dataIndex]).filter(v => v !== undefined).join(',')).join('\n'))
    if (data.data.length >= filterInfo.page_size) {
      res.push('\n')
      handleExport(page_num + 1, res)

      return
    }
    const resString = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(res.join(''))
    const link = document.createElement('a')
    link.href = resString
    link.download = 'export.csv'
    link.click()
  }

  const handleOpenFilterColumns = () => {
    const queryType = location.pathname.split('/')[2]
    const hiddenInfo = getHiddenItem()[queryType!] || {}
    setTimeout(() => {
      filterColumnsRef.current?.setFieldsValue(filterColumnsList.reduce((pre, cur) => ({...pre, [cur.dataIndex]: hiddenInfo[cur.dataIndex]}), {}))
    }, 0)
    setVisibleFilterModal(true)
  }

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 90 || current > moment().endOf('day');
    }
    
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    initSearch().catch() // 初始列
    setFilterInfo(initFelterInfo) // 初始化搜索条件
    filterRef.current?.resetFields() // 初始筛选
  }, [location])

  useEffect(() => {
    initOrgList().catch()
  }, [])
  
  return <div className={styles.container}>
    <div className={styles.search_container}>
      <Form ref={filterRef} layout='vertical' className={styles.form_container}>
        <Row wrap>
          <Col span={5}>
            <Form.Item name='search_key'>
              <Input placeholder='请输入患者ID或姓名' />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item name='org_id_list'>
              <Select placeholder='请选择机构' maxTagTextLength={4} maxTagCount={2} options={orgList} mode='multiple' />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name='period'>
              <DateRange datePickerProps={{disabledDate: disabledDate}} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.search_button_container}>
        <Button onClick={() => handleExport()}>导出</Button>
        {isShowFilterButton && <Button onClick={handleOpenFilterColumns}>筛选列表</Button>}
        <Button onClick={handleClearSearch}>清空</Button>
        <Button onClick={handleSearch}>查询</Button>
      </div>

    </div>
    <div className={styles.table_container}>
      <Table
        dataSource={data}
        columns={column}
        pagination={false}
        className='scroll-table'
        scroll={{y: 'auto'}}
      />
      <SimplePagination
        pageNum={filterInfo.page_num}
        pageSize={filterInfo.page_size}
        disabledInfo={{ preButton: filterInfo.page_num === 1, nextButton: data.length < filterInfo.page_size }}
        onChangePagination={handleChangeFilter}
      />
    </div>
    <Modal
      open={visibleFilterModal}
      title='隐藏模块'
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <FilterColumnsForm formRef={filterColumnsRef} itemList={filterColumnsList} />
    </Modal>
  </div>
}
