import { Checkbox, Form, FormInstance, Row } from 'antd'
import React from 'react'
import { ColumnsType } from '../../../../assets/types/baseApi';

type PropsType = {
  formRef: React.RefObject<FormInstance<null>>;
  itemList: ColumnsType[];
}

export const FilterColumnsForm = (props: PropsType) => {
  const { formRef, itemList } = props

  return <Form ref={formRef}>
    <Row wrap>
      {itemList.map(v => <Form.Item
        valuePropName='checked'
        name={v.dataIndex}
        key={v.dataIndex}
      >
        <Checkbox>{ v.title }</Checkbox>
      </Form.Item>)}
    </Row>
  </Form>
}
