import React from 'react'
import { LoginBackground, LoginCompanyIcon, } from '../../assets'
import styles from './index.module.less'
import LoginCard from './component/LoginCard'

export default () => {
  return <div className={styles.login_container}>
      <img src={LoginCompanyIcon as unknown as string} className={styles.login_logo} alt="" />
      <img src={LoginBackground} className={styles.background} />
      <LoginCard />
  </div>
}
