import React from 'react'
import styles from './index.module.less'
import { Button, InputNumber, Select } from 'antd'

const pageSizeList = [{
  label: '10条/页',
  value: 10,
  key: 10,
},{
  label: '20条/页',
  value: 20,
  key: 20,
},{
  label: '50条/页',
  value: 50,
  key: 50,
},{
  label: '100条/页',
  value: 100,
  key: 100,
}]

type PropsType = {
  pageSize: number;
  pageNum: number;
  disabledInfo?: {
    preButton: boolean;
    nextButton: boolean;
  };
  onChangePagination: (query: {[props: string]: any}) => void;
}

export const SimplePagination = (props: PropsType) => {
  const { pageSize, pageNum, disabledInfo, onChangePagination } = props

  return <div className={styles.simple_pagination}>
    <Button onClick={() => {onChangePagination({page_num: pageNum - 1})}} disabled={disabledInfo?.preButton}>上一页</Button>
    <InputNumber min={1} max={1000} onChange={(val) => { onChangePagination({ page_num: val }) }} value={pageNum} />
    <Button onClick={() => {onChangePagination({page_num: pageNum + 1})}} disabled={disabledInfo?.nextButton || pageNum === 1000}>下一页</Button>
    <Select
      options={pageSizeList}
      value={pageSize}
      onSelect={(val) => { onChangePagination({page_size: val})}}
    />
  </div>
}
