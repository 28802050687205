import React, { memo, FC } from 'react'
// import type { ReactNode, FC } from 'react'
import { Button } from 'antd'
import { login } from '../../../../utils/keycloak'
import styles from '../../index.module.less'

// import { login } from '@/utils/keycloak'

interface IProps {

}

const LoginCard: FC<IProps> = (props: IProps) => {
  // const isPermissions = false
  // const dispatch = useAppDispatch()

  const onLoginAction = async () => {
    //添加跳转的逻辑
    // dispatch(saveTokenDataAction())
    await login()
  }

  return (
    <div className={styles.login_card}>
      <div className={styles.card_title}>登录</div>
      <Button className={styles.login_btn} onClick={onLoginAction}>
        {' '}
      </Button>
      <div className={styles.login_tips_text}>
        点击飞书登录
      </div>
    </div>
  )
}

export default memo(LoginCard)
