import { Col, Form, FormInstance, Row } from 'antd';
import React from 'react'
import { PreviewItem } from './PreviewItem';

export type BizItemList = {
  Component: any;
  key: any;
  name: string;
  label?: string;
  colAttrs?: any;
  itemAttrs?: any;
  isPreview?: boolean;
  PreviewView?: any;
}

type PropsType = {
  formRef: React.RefObject<FormInstance<null>>;
  itemList: BizItemList[];
  isPreview?: boolean;
  formAttrs?: any;
  rowAttrs?: any;
}

export const BizWrapForm = (props: PropsType) => {
  const { formRef, itemList, isPreview, rowAttrs, formAttrs } = props

  return <Form ref={formRef} layout='vertical' {...formAttrs}>
    <Row wrap justify='space-between' {...rowAttrs}>
      {itemList.map(v => <Col span={11} key={v.key} {...v.colAttrs}>
        <Form.Item
          name={v.name}
          label={v.label}
          {...v.itemAttrs}
        >
          {(isPreview || v.isPreview)? (v.PreviewView? <v.PreviewView />: <PreviewItem />): <v.Component />}
        </Form.Item>
      </Col>)}
    </Row>
  </Form>
}
