import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { request } from '../../utils/request'
import { OptionColumn, initFelterInfo, pageInfo, parseObj } from './config'
import { Button, FormInstance, Modal, Table } from 'antd'
import { SimplePagination } from '../SimplePagination'
import styles from './index.module.less'
import { BizWrapForm } from './component/BizWrapForm'
import { AccuracyResponseApi, CreateRoleApi, FetchAccuracyApi, OrgResponseApi, UpdateRoleApi } from '../../assets/types/apiTypes'
import { ColumnsType } from '../../assets/types/baseApi'

export default () => {
  const location = useLocation()
  const [filterInfo, setFilterInfo] = useState<FetchAccuracyApi>(initFelterInfo)
  const [columns, setColumns] = useState<ColumnsType[]>()
  const [isCreate, setIsCreate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [data, setDate] = useState<any[]>([])

  const formRef = useRef<FormInstance>(null)

  const handleChangeFilter = (query: any) => {
    const resQuery = { ...filterInfo, ...query }
    setFilterInfo(resQuery)
    searchPage(resQuery)
  }

  const searchPage = async (query: Partial<FetchAccuracyApi> = {}) => {
    try {
      const nextStr = pageInfo[location.pathname].tableDataLevel
      const res = parseObj(await request<FetchAccuracyApi, AccuracyResponseApi>(pageInfo[location.pathname].apiContent.tableApi, { method: 'post',data: { ...query } }), nextStr)
      setDate(res)

    } catch (error) {
      
    }
  }

  const handleShowModal = (isCreate = false, query: Partial<FetchAccuracyApi> = {}) => {
    if (!isCreate) {
      setTimeout(() => {
        formRef.current?.setFieldsValue(query)
      }, 0);
    }
    setIsCreate(isCreate)
    setShowModal(true)
  }

  const handleOk = async () => {
    try {
      await formRef.current?.validateFields()

      const val = formRef.current?.getFieldsValue()
      await request<OrgResponseApi | CreateRoleApi | UpdateRoleApi, void>(pageInfo[location.pathname].apiContent[isCreate ? 'createApi' : 'updateApi'], {
        data: val,
        method: 'post'
      })
      initPage()
      handleCancel()
    } catch (error) {
      
    }
  }

  const handleCancel = () => {
    formRef.current?.resetFields()
    setShowModal(false)
  }

  const initPage = () => {
    searchPage(initFelterInfo)
    setFilterInfo(initFelterInfo)
  }

  useEffect(() => {
    initPage()
    setColumns([...pageInfo[location.pathname].columns, OptionColumn(handleShowModal)])
  }, [location])

  return <div className={styles.container}>
    <div className={styles.header}>
      <Button onClick={() => handleShowModal(true)}>新增</Button>
    </div>
    
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey='id'
      className='scroll-table'
      scroll={{y: 'auto'}}
    />
    {pageInfo[location.pathname].showPagization && <SimplePagination
        pageNum={filterInfo.page_num}
        pageSize={filterInfo.page_size}
        disabledInfo={{ preButton: filterInfo.page_num === 1, nextButton: data.length < filterInfo.page_size }}
        onChangePagination={handleChangeFilter}
    />}
    <Modal
      open={showModal}
      title={pageInfo[location.pathname].modalContent[isCreate? 'createModalTitle': 'UpdateModalTitle']}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <BizWrapForm formRef={formRef} itemList={pageInfo[location.pathname].formItemList(!isCreate)} />
    </Modal>
  </div>
}
