import { DefaultValue, atom, selector } from 'recoil';
import { MenuItemType } from '../assets/types/baseApi';

export const menuKeyState = atom<MenuItemType[]>({
  key: 'menuKey',
  default: [],
})

export const useLineAtom = selector<MenuItemType[]>({
  key: 'useMenuAtom',
  get: ({ get }) => {
    return get(menuKeyState)
  },
  set: ({ set }, value) => {
    if (!(value instanceof DefaultValue)) {
      set(menuKeyState, value)
    }
  },
})
