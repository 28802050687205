import { Col, DatePicker, Row, Select } from 'antd'
import React from 'react'
import { DateRangeEnum, defaultOptions } from './config'

export const DateRange = (props: any) => {
  const { value = { rangeType: DateRangeEnum.thisMonth }, onChange, datePickerProps } = props

  const handleChangeSelect = (val: any) => {
    onChange({rangeType: val, date: undefined})
  }

  const handleChangePicker = (val: any) => {
    onChange({...value, date: val})
  }

  return <Row>
    <Col span={8}>
    <Select
      options={defaultOptions}
      value={value.rangeType}
      onChange={handleChangeSelect}
    ></Select>
    </Col>
    <Col span={16}>
      {value.rangeType === DateRangeEnum.other && <DatePicker.RangePicker {...datePickerProps} value={value.date} onChange={handleChangePicker} style={{ width: '100%' }} />}
    </Col>
  </Row>
}
