import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { IconMap, RouteMap, initRouterList, needReplacePage, notFoundRoute, notNoMenuRoute, routerContainer } from './router'
import { useRecoilState } from 'recoil'
import { useLineAtom } from './recoil/menu'
import { initKeycloak } from './utils/keycloak'
import { AccountInfoAtom } from './recoil/accountInfo'
import { getUserInfo } from './utils/request/dcApi'
import { MenuItemType } from './assets/types/baseApi'

export default () => {
    const [routerList, setRouterList] = useState<MenuItemType[]>([routerContainer])
    const [, setMenu] = useRecoilState(useLineAtom)
    const [, setAccountInfo] = useRecoilState(AccountInfoAtom)
    const location = useLocation()
    const navigate = useNavigate()
    const RouterDom = useCallback((list: MenuItemType[]): React.ReactElement[] => {
        return list.map(v => {
            return <Route key={v.route} path={v.route} Component={RouteMap[v.type] || undefined}>
                {v.sub && RouterDom(v.sub)}
            </Route>
        })

    }, [])

    const initRouter = async () => {
        try {
            const info = await getUserInfo()
            setAccountInfo(info)

            if (!info.menu_list.length) {
                navigate('/403');

                return
            }

            const sub = info.menu_list.map(v => ({ ...v, sub: v.sub ? [...v.sub, notFoundRoute] : undefined }))
            setRouterList([
                { ...routerContainer, sub: [notNoMenuRoute, notFoundRoute,...sub] },
            ])
            let defaultRouter = '/'
            try {
               defaultRouter = `${sub[0].id}${sub[0].sub![0].route}`
            } catch (error) {
                
            }

            setMenu(info.menu_list.map(v => ({ ...v, icon: IconMap[v.id!] })))
            if (needReplacePage.some(v => v === location.pathname )) {
                navigate(defaultRouter)
            }
        } catch (error) {
            // 跳转登录页
            if (location.pathname !== '/login') {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        initKeycloak().then(() => {
            initRouter()
        }).catch(() => {
            if (location.pathname !== '/login') {
                navigate('/login')
            }
        })
    }, [])

    return <Routes >
            {RouterDom(initRouterList)}
            {RouterDom(routerList)}
        </Routes>
}
