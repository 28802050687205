import { DefaultValue, atom, selector } from 'recoil';

export type AccountInfoType = Partial<{
  mobile: number;
  id: number;
  name: string;
  role_name: string;
  sub: string;
}>

export const accountInfoState = atom<AccountInfoType>({
  key: 'accountInfoState',
  default: {},
})

export const AccountInfoAtom = selector<AccountInfoType>({
  key: 'accountInfoAtom',
  get: ({ get }) => {
    return get(accountInfoState)
  },
  set: ({ set }, value) => {
    if (!(value instanceof DefaultValue)) {
      set(accountInfoState, value)
    }
  },
})
