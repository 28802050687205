import { request } from '.'
import { AccountResponseType, AccuracyResponseApi, FetchAccuracyApi, OrgResponseApi } from '../../assets/types/apiTypes'

// 获取用户权限信息
export const getUserInfo = () => {
  return request.post<void, AccountResponseType>('/dc/portal/api/user/info')
}

// 获取机构列表
export const getOrgList = () => {
  return request.post<void, OrgResponseApi[]>('/dc/portal/api/org/list')
}

// 获取精度列表
export const searchAccuracyList = (query: FetchAccuracyApi) => {
  return request.post<FetchAccuracyApi, AccuracyResponseApi>('/dc/report/api/queryreport', query)
}
