import React from 'react'
import { Input, Select, Space } from 'antd'
import { BizItemList } from './component/BizWrapForm'
import { FetchAccuracyApi } from '../../assets/types/apiTypes'
import { ColumnsType } from '../../assets/types/baseApi'

const roleColumn: ColumnsType[] = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
},{
  title: '姓名',
  dataIndex: 'name',
  key: 'name',
},{
  title: '电话',
  dataIndex: 'mobile',
  key: 'mobile',
  render: (text: string) => text? text.replace(/^(\d{3})(?:\d+)(\d{4})/, '$1****$2'): text
},{
  title: '角色名称',
  dataIndex: 'role_name',
  key: 'role_name',
  render: (text: string) => roleMap.find(v => v.value === text)?.label || ''
}]

const roleMap = [{
  label: '管理员',
  value: 'admin',
  key: 'admin',
},{
  label: '普通用户',
  value: 'common_user',
  key: 'common_user',
},{
  label: '无权限用户',
  value: 'inactive_user',
  key: 'inactive_user',
}]

const roleFormItemList = (isPreview = false): BizItemList[] => {
  const idItem = {
    label: 'ID',
    name: 'id',
    key: 'key',
    Component: Input,
    isPreview,
    itemAttrs: {
      required: true,
      rules: [{ required: true, message: '必填' }]
    },
  }

  const normalItems = [{
    label: '姓名',
    name: 'name',
    key: 'name',
    Component: Input,
    isPreview,
    itemAttrs: {
      required: true,
      rules: [{ required: true, message: '必填' }]
    },
  },{
    label: '电话',
    name: 'mobile',
    key: 'mobile',
    Component: Input,
    isPreview,
    PreviewView: (props: any) => <>{props.value?.replace(/^(\d{3})(?:\d+)(\d{4})/, '$1****$2') || '' }</>,
    itemAttrs: {
      required: true,
      rules: [{
        required: true, message: '必填',
      },               {
        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
        message: '请填写正确的手机号',
        validateTrigger: 'onBlur',
      }]
    },
  },{
    label: '角色名称',
    name: 'role_name',
    key: 'role_name',
    itemAttrs: {
      required: true,
      rules: [{ required: true, message: '必填' }]
    },
    Component: (props: any) => <Select options={roleMap.filter(v => v.value !== 'admin')} {...props} />,
  }]

  return isPreview? [idItem, ...normalItems]: normalItems
}

const orgColum: ColumnsType[] = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
},{
  title: '机构名称',
  dataIndex: 'name',
  key: 'name',
},{
  title: '机构简称',
  dataIndex: 'short_name',
  key: 'short_name'
}]

const orgFormItemList = (isPreview = false): BizItemList[] => [{
  label: 'ID',
  name: 'id',
  key: 'key',
  Component: Input,
  isPreview,
  itemAttrs: {
    required: true,
    rules: [{ required: true, message: '必填' }]
  },
},{
  label: '机构名称',
  name: 'name',
  key: 'name',
  Component: Input,
  itemAttrs: {
    required: true,
    rules: [{ required: true, message: '必填' }]
  },
},{
  label: '机构简称',
  name: 'short_name',
  key: 'short_name',
  Component: Input,
  itemAttrs: {
    required: true,
    rules: [{ required: true, message: '必填' }]
  },
}]

export const pageInfo = {
  '/system_manager/role': {
    apiContent: {
      tableApi: '/dc/portal/api/user/page',
      updateApi: '/dc/portal/api/user/update',
      createApi: '/dc/portal/api/user/save'
    },
    tableDataLevel: 'records',
    columns: roleColumn,
    showPagization: true,
    modalContent: {
      createModalTitle: '新增用户',
      UpdateModalTitle: '编辑用户',
    },
    formItemList: roleFormItemList,
  },
  '/system_manager/org': {
    apiContent: {
      tableApi: '/dc/portal/api/org/list',
      createApi: '/dc/portal/api/org/save',
      updateApi: '/dc/portal/api/org/update',
    },
    tableDataLevel: '',
    columns: orgColum,
    modalContent: {
      createModalTitle: '新增机构',
      UpdateModalTitle: '编辑机构',
    },
    formItemList: orgFormItemList,
  }
}

export const initFelterInfo: FetchAccuracyApi = {
  page_num: 1,
  page_size: 50,
}

export const OptionColumn = (cb: (isCreate: boolean, query: any) => void) => ({
  title: '操作',
  dataIndex: 'action',
  render: (_: any, record: any) => <Space size="middle">{record.role_name !== 'admin' && <a onClick={() => cb(false, record)}>编辑</a>}</Space>
})

export const parseObj = (obj: any, str: string) => {
  if (!str) return obj
  let res = obj
  const strList: string[] = str.split('.').filter(v => v)
  strList.forEach(v => {
    res = res[v]
  })
  return res
}
