import { hiddenItemListKey } from './config'

export const getHiddenItem = () => {
  return JSON.parse(localStorage.getItem(hiddenItemListKey) || '{}')
}

export const setHiddenItem = (key: string, query: any) => {
  const preVal = getHiddenItem()
  localStorage.setItem(hiddenItemListKey, JSON.stringify({...preVal, [key]: query}))
}
